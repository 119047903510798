<template>
  <div>
    <!-- Nombre -->
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.methodological_strategy.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Descripción -->
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="form-description rich-text-content mb-2"
        v-html="$v.methodological_strategy.description.$model"
        @click="$bvModal.show(`edit-description-modal`)"
      ></div>
      <b-modal
        :id="`edit-description-modal`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="methodological_strategy"
          :Text="methodological_strategy.description"
          @save="patchDescription"
          @close="$bvModal.hide(`edit-description-modal`)"
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Se encuentra activo -->
    <b-form-group
      label-for="input-is_active"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <template #label>
        <div class="block-title-container">
          <div>Vigencia</div>
          <div class="ml-2">
            <InfoTooltip
              :tooltip_text="`Al estar vigente la ${$getVisibleNames(
                'mesh.methodologicalstrategy',
                false,
                'Estrategia Metodológica'
              )} será visible en ${$getVisibleNames(
                'manual.matriz_2',
                false,
                'Matriz 2'
              )}.`"
            ></InfoTooltip>
          </div>
        </div>
      </template>
      <b-form-checkbox
        class="mt-2"
        v-model="methodological_strategy.is_active"
        size="md"
      >
      </b-form-checkbox>
    </b-form-group>
    <b-form-group
      v-if="!isNaN(methodological_strategy.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2 mb-2"
    >
      <ModificationDate
        :Information="methodological_strategy"
      ></ModificationDate>
    </b-form-group>
    <div class="row">
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "MethodologicalStrategyForm",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  mixins: [validationMixin],
  props: {
    MethodologicalStrategy: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          is_active: true,
          update_date: null,
          updated_by: null,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      methodological_strategy: { ...this.MethodologicalStrategy },
    };
  },
  validations: {
    methodological_strategy: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {},
      is_active: {},
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.methodological_strategy[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.methodological_strategy.$touch();
      if (this.$v.methodological_strategy.$anyError) {
        return;
      }
      if (isNaN(this.methodological_strategy.id)) {
        this.createMethodologicalStrategy();
      } else this.updateMethodologicalStrategy();
    },
    patchDescription(object, text) {
      this.methodological_strategy.description = text;
    },
    createMethodologicalStrategy() {
      this.methodological_strategy.school = this.institution_id;
      this.$store
        .dispatch(
          names.POST_METHODOLOGICAL_STRATEGY,
          this.methodological_strategy,
          this.methodological_strategy.school
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.methodologicalstrategy",
              false,
              "Estrategia Metodológica"
            ) + " creada."
          );
          this.$emit("created", response);
        });
    },
    updateMethodologicalStrategy() {
      this.methodological_strategy.school = this.institution_id;
      this.$store
        .dispatch(
          names.UPDATE_METHODOLOGICAL_STRATEGY,
          this.methodological_strategy,
          this.methodological_strategy.school
        )
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "mesh.methodologicalstrategy",
              false,
              "Estrategia Metodológica"
            ) + " actualizada."
          );
          this.$emit("updated", response);
        });
    },
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.2rem 0.5rem;
  line-height: 1.5;
}
.form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>